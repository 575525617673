@font-face {
  font-family: "NewRail";
  src: local("NewRail"), url("./assets/NewRail.otf") format("opentype");
}

body {
  background-color: #1e1e1e;
  height: 100vh;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: "NewRail",sans-serif;
  color: #fff;
  line-height: 1.15;
  overflow: hidden;
}

.header {
  display: flex;
    justify-content: space-between;
    margin: 28px;
    align-items: flex-start;
}

h1 {
  color: #fff;
  margin: 0;
  font-size: 40px;
  font-weight: 500;
}

section {
  height: 100vh;
  max-width: 100vw;
  color: #fff;
}

.logo {
  width: 30vw;
}


.white-line {
  height: 25px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 30vh;
  z-index: 2;
}

.orange-line {
  border-right: 25px solid #f26a29;
    border-bottom: 25px solid #f26a29;
    border-radius: 0 0 100px 0;
    width: 60vw;
    height: 25vh;
    top: 0;
}

.orange-line, p {
  position: absolute;
}

.render, p {
  bottom: 28px;
}

p {
  right: 28px;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.render {
  background-size: cover;
  background-repeat: no-repeat;
  width: 66vw;
  height: 60vh;
  position: fixed;
  left: 28px;
}

@media (max-width: 800px) {
  .logo {
    right: 20px;
    height: 28px;
    width: 150px;
    transform: translate(100%) rotate(90deg);
    transform-origin: left top;
    -webkit-transform: translate(100%) rotate(90deg);
    -webkit-transform-origin: left top;
}

.logo, h1 {
  position: absolute;
}

h1 {
  font-size: 25px;
  line-height: 1.1;
  left: 20px;
}

  p {
    font-size: 14px;
    left: 20px;
    bottom: 45%;
}

  .white-line {
    margin-top: 10px;
    height: 15px;
    top: 33%;
    left:0;
}

  .orange-line {
    width: 75vw;
    border-right: 15px solid #f26a29;
    border-bottom: 15px solid #f26a29;
    border-radius: 0 0 60px 0;
    height: 30%;
    margin-bottom: 10px;
    left:0;
}

.render {
 
    width: 90vw;
    left: 20px;
    height: 40%;

}
}
